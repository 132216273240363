*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: slateblue;
}

.BoxTarefas{
  width: 80%;
  max-width: 800px;
  background-color: white;
  padding: 30px 50px;
  margin: 20px auto;
  border-radius: 10px;
}

.BoxTarefas h2{
  text-align: center;
  padding-bottom: 30px;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 2px;
  border-bottom: 2px dotted #ddd;
  color: indigo;
}

.tarefasingle{
  font-size: 15px;
  padding: 10px 2%;
  border-bottom: 1px solid #dddddd;  
  display: flex;
  align-items: center;
}

.tarefasingle p{
  cursor: pointer;
  display: block;
  width: 100%;
  color: indigo;
}

.tarefasingle span{
  color: red;
  cursor: pointer; 
  display: block;
  font-size: 20px;
  font-weight: bold;
  background-color: rgb(235, 235, 235);
  border-radius: 25%;
  padding: 0 10px;
}

.AddTarefas{
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: indigo;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  line-height: 41px;
  color: white;
  font-size: 39px;
  font-weight: 700;
}

.AddTarefas:hover{
  background-color: rgb(103, 0, 177);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  line-height: 45px;
  font-size: 45px;
}

.modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modalcontent{
  background-color: white;
  border-radius: 10px;
  width: 70%;
  max-width: 400px;
  height: 200px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translateX(-50%);

}

.modalcontent h3{
  padding: 10px 0;
  font-size: 26px;
  color: indigo;
  letter-spacing: 1px;
}

.modalcontent input{
  margin-top: 20px;
  border: none;
  border-bottom: 1px solid rgb(150, 150, 150);
  width: 100%;
  max-width: 350px;
  height: 40px;
  display: inline-block;
  font-size: 20px;
  padding-left: 5px;
}

.modalcontent button{
  display: inline-block;
  margin-top: 30px;
  width: 70%;
  height: 40px;
  background-color:slateblue;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.modalcontent button:hover{
  background-color: rgb(84, 72, 161);
}

.logo{
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  color: rgb(236, 146, 10);
  font-size: 20px;
  font-weight: bold;
}

.logo span{
  color: rgb(34, 221, 9);
}